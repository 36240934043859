// //------------------------------------------------------------------------------------

// import { useState } from 'react';
// import '../external.scss';
// import baseUrl from '../../../scripts/baseUrl';
// import axios from 'axios';
// import { useNavigate } from 'react-router-dom';

// //------------------------------------------------------------------------------------

// const ForgetPassword = () => {
//     const navigate = useNavigate();
//     const [data, setData] = useState({
//         email: ''
//     });

//     const handleChange = (e) => {
//         setData(prev => ({ ...prev, [e.target.name]: e.target.value }));
//     }

//     const isExist = async () => {
//         try {
//             const res = await axios.get(`${baseUrl}/login/isExist/${data.email}`);
//             if (res.data) {
//                 navigate(
//                     '/otp',
//                     { state: { email: data.email } }
//                 );
//             } else {
//                 alert('User does not exists.');
//             }
//         } catch (err) {
//             console.log(err);
//         }
//     }

//     return (
//         <>
//             <div className='external-screen'>
//                 <div className="frame">
//                     <div className="outlined-card">
//                         <form action="">
//                             <div className="app-title headline-small">Forget Password</div>

//                             <div className="input-container">
//                                 <input type="email" id='email' name='email' className='inputfield' placeholder='Email' maxLength={255} onChange={handleChange} />
//                                 <div id='email-hint' className="input-hints">&nbsp;</div>
//                             </div>

//                             <div className="bottom-buttons">
//                                 <div></div>
//                                 <button type='button' className='filled-button' onClick={isExist}>Next</button>
//                             </div>
//                         </form>
//                     </div>
//                 </div>
//             </div >
//         </>
//     )
// }

// export default ForgetPassword;

// //------------------------------------------------------------------------------------



import { useState } from 'react';
import '../external.scss';
import baseUrl from '../../../scripts/baseUrl';
import axios from 'axios';
import { encryptData } from '../../../scripts/encrypt';
import Loading from '../../../components/Loading/Loading';
 import { useNavigate } from 'react-router-dom';


const ForgetPassword = () => {
    const [loader, setLoader] = useState(false);
    const [mobile, setMobile] = useState('');
    const [showPasswordField, setShowPasswordField] = useState(false);
    let [newPassword, setNewPassword] = useState('');

    const navigate = useNavigate()

    const checkMobile = async () => {
        if (!mobile) {
            alert('Please enter your mobile number.');
            return;
        }

        try {
            setLoader(true);
            const res = await axios.get(`${baseUrl}/login/isExist/mobile/${mobile}`);
            console.log("res exist - ",res.data.exists);
            if (res.data.exists) {
                setShowPasswordField(true);
            } else {
                alert('Mobile number does not exist.');
            }
        } catch (err) {
            console.error(err);
            alert('An error occurred while checking the mobile number.');
        }
        setLoader(false);
    };

    const resetPassword = async () => {
        if (!newPassword) {
            alert('Please enter a new password.');
            return;
        }

        try {
            setLoader(true);
            // newPassword = encryptData(newPassword)
            const res = await axios.post(`${baseUrl}/login/resetPassword`, {
                mobile,
                newPassword,
            });
            console.log("pa - ",res.data);
            if (res.data) {
                alert('Password reset successfully!');
                setShowPasswordField(false);
                setMobile('');
                setNewPassword('');
            } else {
                alert('Failed to reset the password.');
            }

            navigate(
                                 '/login',
                                    
                );

        } catch (err) {
            console.error(err);
            alert('An error occurred while resetting the password.');
        }
        setLoader(false);
    };

    return (
        <>
            <div className='external-screen'>
                <div className="frame">
                    <div className="outlined-card">
                        <form>
                            <div className="app-title headline-small">Reset Password</div>

                            {!showPasswordField && (
                                <>
                                    <div className='label label-medium'>Enter your mobile number</div>
                                    <div className="input-container">
                                        <input
                                            type="text"
                                            name="mobile"
                                            className="inputfield"
                                            placeholder="Mobile Number"
                                            maxLength={10}
                                            value={mobile}
                                            onChange={(e) => setMobile(e.target.value)}
                                        />
                                    </div>

                                    <div className="bottom-buttons">
                                        <div></div>
                                        <button
                                            type="button"
                                            className="filled-button"
                                            onClick={checkMobile}
                                        >
                                            Verify Mobile
                                        </button>
                                    </div>
                                </>
                            )}

                            {showPasswordField && (
                                <>
                                    <div className='label label-medium'>Enter your new password</div>
                                    <div className="input-container">
                                        <input
                                            type="password"
                                            name="newPassword"
                                            className="inputfield"
                                            placeholder="New Password"
                                            maxLength={255}
                                            value={newPassword}
                                            onChange={(e) => setNewPassword(e.target.value)}
                                        />
                                    </div>

                                    <div className="bottom-buttons">
                                        <div></div>
                                        <button
                                            type="button"
                                            className="filled-button"
                                            onClick={resetPassword}
                                        >
                                            Reset Password
                                        </button>
                                    </div>
                                </>
                            )}
                        </form>
                    </div>
                </div>
            </div>

            {loader && <Loading />}
        </>
    );
};

export default ForgetPassword;
