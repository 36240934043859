//------------------------------------------------------------------------------------

import '../external.scss';

import { Link, Navigate } from 'react-router-dom';
import { Visibility, VisibilityOff } from '../../../components/Icons/Icons.js';
import baseUrl, { adminBaseUrl } from '../../../scripts/baseUrl';
import { useEffect, useState } from 'react';

import Footer from '../../../components/Footer/Footer';
import Loading from '../../../components/Loading/Loading';
import Logo from '../../../assets/logo.png';
import axios from 'axios';
import checkLoginStatus from '../../../scripts/checkLoginStatus.js';
import { encryptData } from '../../../scripts/encrypt';
import theme from '../../../scripts/theme';

//------------------------------------------------------------------------------------

const Login = () => {
	document.title = `Login • Pune Division Catering Vendor Id`;

	const [validLogin, setValidLogin] = useState(false);
	const [loader, setLoader] = useState(false);
	const [stations, setStations] = useState(['']);
	const [shopNameListAsPerStation, setShopNameListAsPerStation] = useState([]);

	const [owner, setOwner] = useState({
		mobile: '',
		station: '',
		shopName: '',
		password: '',
	});
	const [passwordVisibility, setPasswordVisibility] = useState(false);

	const getStation = async () => {
		try {
			setLoader(true);
			const res = await axios.get(`${adminBaseUrl}/stations`);
			setStations(res.data);
		} catch (error) {
			console.log(error);
		}
		setLoader(false);
	};

	useEffect(() => {
		//checkLoginStatus();
		status();
		getStation();

		//blocking some keys for username
		// const username = document.getElementById('username');
		// username.addEventListener('keypress', (event) => {
		//     var regex = new RegExp("^[a-zA-Z0-9@.]+$");
		//     var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
		//     if (!regex.test(key)) {
		//         event.preventDefault();
		//         return false;
		//     }
		// });

		/* const body = document.getElementsByTagName('body')[0];
        const onEnter = document.getElementById('on-enter');
        body.addEventListener("keypress", function (event) {
            if (event.key === "Enter") {
                event.preventDefault();
                onEnter.click();
            }
        }); */
	}, []);

	// Enter click
	// useEffect(() => {
	//     const body = document.getElementsByTagName("body");
	//     body.addEventListener("keyup", (event) => {
	//       event.preventDefault();
	//       if (event.keyCode === 13) {
	//         document.getElementById("on-enter").click();
	//       }
	//     });
	// }, []);

	const status = async () => {
		setLoader(true);
		if (await checkLoginStatus()) {
			setValidLogin(true);
		} else {
			setValidLogin(false);
		}
		setLoader(false);
	};

	const login = async () => {
		if (owner.station === '') {
			alert('Please select station');
			return;
		}
		if (owner.shopName === '') {
			alert('Please select stall name');
			return;
		}

		try {
			setLoader(true);
			let thisOwner = { ...owner };
			thisOwner.password = encryptData(owner.password);

			const res = await axios.post(`${baseUrl}/login`, thisOwner);

			if (res.data) {
				localStorage.setItem('owner', JSON.stringify(res.data));
				setValidLogin(true);
				theme.set();
			} else {
				alert('User not approved yet or invalid user.');
			}
		} catch (err) {
			console.log(err);
		}
		setLoader(false);
	};

	const validate = () => {
		let username = document.getElementById('username');
		let usernameHint = document.getElementById('username-hint');
		let password = document.getElementById('password');
		let passwordHint = document.getElementById('password-hint');

		let isUsernameValid = false;
		let isPasswordValid = false;

		//username validation
		if (username.value.length === 0) {
			username.parentElement?.classList.add('error');
			usernameHint.innerHTML = 'Please enter mobile';
			isUsernameValid = false;
		} else if (username.value.length > 10) {
			username.parentElement?.classList.add('error');
			usernameHint.innerHTML = 'Invalid mobile number';
			isUsernameValid = false;
		} else {
			username.parentElement?.classList.remove('error');
			usernameHint.innerHTML = '&nbsp;';
			isUsernameValid = true;
		}

		//password validation
		const passwordRegex =
			/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/;
		if (password.value.match(passwordRegex)) {
			password.parentElement?.classList.remove('error');
			passwordHint.innerHTML = '&nbsp;';
			isPasswordValid = true;
		} else {
			password.parentElement?.classList.add('error');
			passwordHint.innerHTML =
				'Please enter password between 8 to 15 characters which contain at least one lowercase letter, one uppercase letter, one numeric digit, and one special character.';
			isPasswordValid = false;
		}

		//if valid
		if (isUsernameValid && isPasswordValid) {
			login();
		} else {
			setValidLogin(false);
		}
	};

	const handleChange = (e) => {
		setOwner((prev) => ({ ...prev, [e.target.name]: e.target.value }));
	};

	const enterClick = (e) => {
		if (e.key === 'Enter' || e.keyCode === 13) {
			let button = document.getElementById('on-enter');
			button.click();
		}
	};

	const onStationChange = async (event) => {
		setLoader(true);

		let currentStation = event.target.value;
		try {
			const accessToken =
				'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MCwiaWF0IjoxNjkwNDMzMTUxfQ.hN2owEvSUCKAuZ2FpMls3aMEoKwVifjOVmxvC0If2_Q';

			const res = await axios.get(`${baseUrl}/shopNames/${currentStation}`, {
				headers: {
					'access-token': accessToken,
				},
			});

			setShopNameListAsPerStation(res?.data);
			console.log("stall namee " ,res?.data );
		} catch (err) {
			console.log(err);
		}

		setLoader(false);
	};

	return (
		<>
			<div className='external-screen'>
				<div className='frame'>
					<div className='outlined-card'>
						<form onKeyUp={(event) => enterClick(event)}>
							<div className='form-app-headline'>
								<div className='app-logo'>
									<img src={Logo} />
								</div>
								<div className='app-title title-small'>
									Pune Division Catering Vendor Id
								</div>
							</div>

							<div className='app-title headline-large'>Login</div>

							{/* <div className='label label-medium'>Enter your email</div>
                            <div className="input-container">
                                <input type="text" id='username' name='email' className='inputfield' placeholder='Email' onChange={handleChange} />
                                <div id='username-hint' className="input-hints">&nbsp;</div>
                            </div> */}

							<div className='label label-medium'>Enter your mobile no.</div>
							<div className='input-container'>
								<input
									type='number'
									id='username'
									name='mobile'
									className='inputfield'
									placeholder='Mobile no.'
									onChange={handleChange}
								/>
								<div
									id='username-hint'
									className='input-hints'
								>
									&nbsp;
								</div>
							</div>

							{/* Station */}
							<div className='label label-medium'>Select station</div>
							<div className='input-container'>
								<select
									id='station'
									className='inputfield'
									name='station'
									onChange={(event) => {
										handleChange(event);
										onStationChange(event);
									}}
								>
									<option
										disabled
										selected
									>
										Select station
									</option>
									{ stations.length>0 && stations?.map((station, index) => {
										return (
											<option
												key={index}
												value={station.station}
											>
												{station.station}
											</option>
										);
									})}
								</select>
								<div
									id='station-hint'
									className='input-hints'
								>
									&nbsp;
								</div>
							</div>

							{shopNameListAsPerStation.length !== 0 && (
								<>
									<div className='label label-medium'>Select stall name</div>
									<div className='input-container'>
										<select
											id='shopName'
											className='inputfield'
											name='shopName'
											onChange={handleChange}
										>
											<option
												disabled
												selected
											>
												Select stall name
											</option>
											{shopNameListAsPerStation.length>0 && shopNameListAsPerStation?.map((data, index) => {
												return (
													<option
														key={`${index}-shopName`}
														value={data.shopName}
													>
														{data.shopName}
													</option>
												);
											})}
										</select>
										<div
											id='station-hint'
											className='input-hints'
										>
											&nbsp;
										</div>
									</div>
								</>
							)}

							<div className='label label-medium'>Enter your password</div>
							<div className='input-container password-inputfield'>
								<input
									type={passwordVisibility ? 'text' : 'password'}
									id='password'
									name='password'
									className='inputfield'
									placeholder='Password'
									onChange={handleChange}
								/>
								<div
									id='password-hint'
									className='input-hints'
								>
									&nbsp;
								</div>
								<div className='password-visibility'>
									<button
										type='button'
										className='icon-button'
										onClick={() => setPasswordVisibility((prev) => !prev)}
									>
										{passwordVisibility ? <VisibilityOff /> : <Visibility />}
									</button>
								</div>
							</div>

							<div className='bottom-buttons'>
								{/* <Link to='/forget-password'>
                                    <button type='button' className='text-button'>Forget password</button>
                                </Link> */}
								<Link to='/register/owner'>
									<button
										type='button'
										className='text-button'
									>
										Dont' have an account, register here
									</button>
								</Link>
								<Link to='/forget-password'>
									<button
										type='button'
										className='text-button'
									>
										Forgot Password
									</button>
								</Link>
								<button
									type='button'
									className='filled-button'
									id='on-enter'
									onClick={validate}
								>
									Login
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
			<Footer />

			{loader && <Loading />}
			{validLogin && (
				<Navigate
					replace
					to='/home/profile'
				/>
			)}
		</>
	);
};

export default Login;

//------------------------------------------------------------------------------------
