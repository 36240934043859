//------------------------------------------------------------------------------------

// const baseUrl = 'https://user.backend.bslcatgvid.in';
// export const adminBaseUrl = 'https://admin.backend.bslcatgvid.in';



//const baseUrl = 'http://localhost:1000';
//export const adminBaseUrl = 'http://localhost:1001';

//for production
 const baseUrl = 'https://ubackend.pdrcvid.com';
 export const adminBaseUrl = 'https://abackend.pdrcvid.com';

export default baseUrl;

//------------------------------------------------------------------------------------
