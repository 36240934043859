//------------------------------------------------------------------------------------

import './Footer.scss';

//------------------------------------------------------------------------------------

const Footer = () => {
	return (
		<div className="footer-container">
			{/* <div className="firm-name label-large">
				Developed By{' '}
				<a
					href="http://msofts.in/"
					target="_blank"
				>
					M-SOFTS DEVELOPMENT
				</a>
				, Bhusawal
			</div> */}
			<div className="copyrights label-medium">
				© All Copyrights Reserved
			</div>
			<a
				className="link label-medium"
				href="https://ppc.pdrcvid.com"
				target="_blank"
			>
				Privacy Policy
			</a>
			<a
				className="link label-medium"
				href="https://tnc.pdrcvid.com"
				target="_blank"
			>
			  Terms & Conditions
			</a>
			<a
				className="link label-medium"
				href="https://rnc.pdrcvid.com"
				target="_blank"
			>
				Refund and Cancellation
			</a>
			<br></br>
			<div className="copyrights label-medium">
				Contacts -{ }support@gmail.com
				<br></br>
				9730004062, 9665130546
			</div>
		</div>
	);
};

export default Footer;

//------------------------------------------------------------------------------------
